





















import { Inject } from 'inversify-props';
import { Component, Vue } from 'vue-property-decorator';

import RatesAnalysisCalendar from '@/modules/rates/components/analysis/calendar/rates-analysis-calendar.vue';
import RatesColorPicker from '@/modules/rates/components/calendar/rates-color-picker.vue';
import RatesDemands from '@/modules/rates/components/rates-demands.vue';
import RatesActions from '@/modules/rates/components/actions/rates-actions.vue';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import RatesHeader from '@/modules/rates/components/rates-header.vue';
import RatesAnalysisComparison from '@/modules/rates/components/analysis/rates-analysis-filters.vue';
import DateDocumentFilter from '@/modules/document-filters/components/date-document-filter.vue';
import TogglePrices from '@/modules/common/modules/rates/components/toggle-prices.vue';
import RatesFiltersService, { RatesFiltersServiceS } from '@/modules/rates/rates-filters.service';
import RatesAnalysisFiltersService, { RatesAnalysisFiltersServiceS } from '@/modules/rates/rates-analysis-filters.service';

@Component({
    components: {
        DateDocumentFilter,
        RatesColorPicker,
        RatesDemands,
        RatesAnalysisCalendar,
        RatesActions,
        PageWrapper,
        RatesHeader,
        RatesAnalysisComparison,
        TogglePrices,
    },
})
export default class RatesAnalysisCalendarPage extends Vue {
    @Inject(RatesFiltersServiceS) private ratesFiltersService!: RatesFiltersService;
    @Inject(RatesAnalysisFiltersServiceS) private ratesAnalysisFiltersService!: RatesAnalysisFiltersService;

    get disableBtn() {
        return this.ratesFiltersService.isProviderAll;
    }

    mounted(): void {
        this.unwatch = this.ratesAnalysisFiltersService.onComparisonValuesChange(this.redirectToTablePage);
    }

    beforeDestroy(): void {
        this.unwatch();
    }

    async redirectToTablePage(values: { value: (string | number), name: string }[]): Promise<any> {
        if (values.length === 2) {
            await this.$router.push('analysis/table');
        }
    }

    unwatch() {}
}
