



















import CalendarItemWrapper from '@/modules/common/components/ui-kit/calendar-item-wrapper.vue';
import PercentFilter from '@/modules/common/filters/percent.filter';
import PriceFilter from '@/modules/common/filters/price.filter';
import Day from '@/modules/common/types/day.type';
import CalendarEventsContainer from '@/modules/events/components/calendar-events-container.vue';
import Occupancy from '@/modules/occupancy/components/occupancy.vue';
import Demand from '@/modules/common/components/ui-kit/demand.vue';
import RatesDotDropdown from '@/modules/rates/components/calendar/rates-dot-dropdown.vue';
import RatesDayScanBtn from '@/modules/rates/components/rates-day-scan-btn.vue';
import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';
import { Inject } from 'inversify-props';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    filters: { PercentFilter, PriceFilter },
    components: {
        RatesDotDropdown,
        CalendarEventsContainer,
        CalendarItemWrapper,
        RatesDayScanBtn,
        Demand,
        Occupancy,
    },
})

export default class HoverBaseCard extends Vue {
    @Inject(RatesServiceS) ratesService!: RatesService;

    @Prop({
        required: true,
        type: Number,
    })
    private day!: Day;

    @Prop({
        type: String,
        default: null,
    })
    private color!: 'yellow' | 'red' | 'green' | 'gray' | null;
}
