
































import Day from '@/modules/common/types/day.type';
import RatesAnalysisFiltersService, { RatesAnalysisFiltersServiceS } from '@/modules/rates/rates-analysis-filters.service';

import { Inject } from 'inversify-props';
import { Component, Prop, Vue } from 'vue-property-decorator';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import RatesAnalysisService, { RatesAnalysisServiceS } from '@/modules/rates/rates-analysis.service';
import RatesCommonService, { RatesCommonServiceS } from '@/modules/common/modules/rates/rates-common.service';
import Available from './cards/available.vue';
import SoldOut from './cards/sold-out.vue';
import NoData from './cards/no-data.vue';
import NotAvailable from './cards/not-available.vue';
import HoverAvailable from './cards/hover/hover-available.vue';
import HoverSoldOut from './cards/hover/hover-sold-out.vue';
import HoverNoData from './cards/hover/hover-no-data.vue';
import HoverNotAvailable from './cards/hover/hover-not-available.vue';

@Component({
    components: {
        NotAvailable,
        NoData,
        SoldOut,
        Available,
        HoverAvailable,
        HoverSoldOut,
        HoverNoData,
        HoverNotAvailable,
    },
})

export default class RatesAnalysisCalendarItem extends Vue {
    @Inject(DocumentFiltersServiceS) documentFiltersService!: DocumentFiltersService;
    @Inject(RatesServiceS) ratesService!: RatesService;
    @Inject(RatesAnalysisServiceS) ratesAnalysisService!: RatesAnalysisService;
    @Inject(RatesAnalysisFiltersServiceS) ratesAnalysisFiltersService!: RatesAnalysisFiltersService;
    @Inject(RatesCommonServiceS) ratesCommonService!: RatesCommonService;
    @Inject(UserServiceS) userService!: UserService;

    @Prop({
        required: true,
        type: Number,
    })
    private day!: Day;

    @Prop({
        required: false,
        type: Number,
    })
    private pickedDay!: Day | null;

    private nonClickableClasses: string[] = ['calendar-item__actions'];

    handleMouseOver() {
        this.$emit('calendar-over', this.day);
    }

    handleMouseLeave() {
        this.$emit('calendar-leave', null);
    }

    get comparisonValue() {
        const { comparisonValues } = this.ratesAnalysisFiltersService;
        return comparisonValues.length ? comparisonValues[0].value : null;
    }

    get settings() {
        if (this.comparisonValue) {
            return this.ratesAnalysisService
                .getSettingsByComparisonValue(this.comparisonValue);
        }
        return null;
    }

    get document() {
        if (this.comparisonValue) {
            return this.ratesAnalysisService
                .getDocumentByComparisonValue(this.comparisonValue);
        }
        return null;
    }

    get isHoverDay() {
        return this.pickedDay === this.day;
    }

    get isNoData() {
        return this.ratesService.isNoData(this.day);
    }

    get isNotAvailable() {
        return this.ratesService.isNA(this.day, this.currentHotelId);
    }

    get isSoldOut() {
        return this.ratesService.isSoldOut(this.day, this.currentHotelId);
    }

    get isNoDataComparison() {
        return this.ratesAnalysisService.isNoData(this.day);
    }

    get isNotAvailableComparison() {
        return this.ratesAnalysisService.isNA(this.day, this.currentHotelId);
    }

    get isSoldOutComparison() {
        return this.ratesAnalysisService
            .isSoldOut(this.day, this.currentHotelId);
    }

    get currentHotelId() {
        return Number(this.$route.params.hotelId) || this.userService.currentHotelId!;
    }

    handleClick(e: MouseEvent) {
        const currentTargetElement = e.currentTarget as HTMLElement;
        const targetElement = e.target as HTMLElement;

        const isNonClickable = (element: HTMLElement): any => {
            if (!element.parentElement) {
                return false;
            }

            if (this.nonClickableClasses.includes(element.parentElement.className)) {
                return true;
            }

            if (element.parentElement!.className === currentTargetElement.className) {
                return false;
            }

            return isNonClickable(element.parentElement);
        };

        if (!isNonClickable(targetElement)) {
            this.$router.push({
                name: `${this.$route.name!}.day-rate`,
                params: { day: String(this.day) },
            });
        }
    }
}
