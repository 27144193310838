








import Day from '@/modules/common/types/day.type';
import { Inject } from 'inversify-props';
import { Component, Prop, Vue } from 'vue-property-decorator';
import BaseCard from './hover-base-card.vue';

@Component({ components: { BaseCard } })
export default class SoldOut extends Vue {
    @Prop({
        required: true,
        type: Number,
    })
    private day!: Day;
}
